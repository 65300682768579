import { Component, Vue } from 'vue-property-decorator';
import Layout from '../Layout';
import DashboardService from '@/Services/DashboardService';
import { ChartCandidates, ChartProcessesReplacementList, ChartProcessesInscription, ChartProcessesEvaluation, Replacement } from '@/Domain/Entities';
import { PaymentsPlanTypes, ProcessType, ProcessPhases, OppositionType, GroupTypes, GraphicType, Constants, PaymentsPlanStatusTypes } from '@/Domain/enum';
import { BarChart, DoughnutChart, BoxLabel, BoxHeader, CardLegendChart, CardProgressionChart } from '@/Components';
import { mapState } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import IIconHoverTrigger from '@/Domain/interfaces/IIconHoverTrigger';

@Component({
    computed: {
        processList() {
            return _.orderBy(this.$data.dataProcessChart, ['bopDate', process => _.deburr(process.title).toLowerCase()], ['desc', 'asc']);
        },
        replacementListOrdered() {
            return _.orderBy(this.$data.replacementLists, [replacement => _.deburr(replacement.name).toLowerCase(), 'creationDate'], ['asc', 'desc']);
        },
        optionsProgressBarProcess() {
            return {
                text: {
                    hideText: true
                },
                progress: {
                    color: this.$data.paymentsPlanStatusTypes.color[this.$data.paymentPlanClient.levelPlanProcess],
                    backgroundColor: this.$data.silverLightColor,
                    inverted: false
                },
                layout: {
                    height: 7,
                    progressPadding: 0,
                    type: 'line'
                }
            };
        },
        adminBlockedProcessIds() {
            return this.$store.state.userStore.adminBlockedProcessIds || [];
        },
        optionsProgressBarCandidates() {
            return {
                text: {
                    hideText: true
                },
                progress: {
                    color: this.$data.paymentsPlanStatusTypes.color[this.$data.paymentPlanClient.levelPlanInscriptions],
                    backgroundColor: this.$data.silverLightColor,
                    inverted: false
                },
                layout: {
                    height: 7,
                    progressPadding: 0,
                    type: 'line'
                }
            };
        },
        ...mapState('languagesStore', { currentLanguage: 'current' })
    },
    components: {
        BarChart, DoughnutChart, BoxLabel, BoxHeader, CardLegendChart, CardProgressionChart
    }
})

export default class Dashboard extends Vue {
    currentLanguage!: string;
    adminBlockedProcessIds!: string[];

    paymentsPlanTypes = PaymentsPlanTypes;
    paymentsPlanStatusTypes = PaymentsPlanStatusTypes;
    processType = ProcessType;
    processPhases = ProcessPhases;
    oppositionType = OppositionType;
    groupTypes = GroupTypes;
    graphicType = GraphicType;
    constants = Constants;
    paymentPlanAvailable: any = [];
    dataBarChart: ChartCandidates | null = null;
    yearActual: number = moment().year();
    dataDoughnutChart: ChartProcessesReplacementList | null = null;
    dataProcessChart: any | null = null;
    loading: boolean = true;
    replacementLists: Replacement[] = [];
    exam: number = 0;
    scale: number = 1;
    silverLightColor: string = '#EEEEEE';
    paymentPlanClient: any = null;
    paymentSelected: any = null;
    isPlanExceed: any = null;
    isPlanWarning: any = null;
    cardLengendIcon: IIconHoverTrigger = { iconClass: 'fas fa-exclamation-triangle', colorClass: 't-red-mid', hoverTrigger: true };

    $refs!: {
        replacementList: HTMLElement;
    };

    setYearSelected(yearSelected: number, type: number) {
        if (type === GraphicType.enum.CANDIDATE) {
            this.getCandidatesYear(yearSelected);
        } else {
            this.getProcessesReplacementListsYear(yearSelected);
        }
    }

    getCandidatesYear(year: number) {
        DashboardService.getCandidatesYear(year)
            .then((response: any) => {
                this.dataBarChart = new ChartCandidates(response);
            }).catch(error => console.log(error));
    }

    getProcessesReplacementListsYear(year: number) {
        DashboardService.getProcessesReplacementListsYear(year)
            .then((response: any) => {
                this.dataDoughnutChart = new ChartProcessesReplacementList(response);
            }).catch(error => console.log(error));
    }

    getReplacementListsActive() {
        DashboardService.getReplacementListsActive()
            .then((response: any) => {
                this.replacementLists = response.map(replacementList => new Replacement(replacementList, this.$store.state.processConfigStore.processConfigGroups));
            }).catch(error => console.log(error));
    }

    getProcessesByYear() {
        Promise.all([DashboardService.getProcessesInscriptionPhaseYear(), DashboardService.getProcessesEvaluationPhaseYear()])
            .then((response: any) => {
                const processesInscription = response[0].map(process => new ChartProcessesInscription(process));
                const processEvaluation = response[1].map(process => new ChartProcessesEvaluation(process));
                this.dataProcessChart = processesInscription.concat(processEvaluation);
            })
            .catch(error => console.log(error));
    }

    getTotalType(type) {
        return this.dataProcessChart ? this.dataProcessChart.filter(process => process.type === type).length : 0;
    }

    goToListManagement(process) {
        if (process.dataIsEmpty) {
            return;
        }
        this.$router.push({
            name: 'ListManagementEdit',
            params: {
                id: process.id,
                fromPage: 'Dashboard'
            }
        });
    }

    goToEvaluation(process) {
        this.$router.push({
            name: 'RatingsEdit',
            params: {
                id: process.id,
                fromPage: 'Dashboard'
            }
        });
    }

    goToReplacement(replacement) {
        this.$router.push({
            name: 'ListReplacementList',
            params: {
                replacementId: replacement.id,
                fromPage: 'Dashboard'
            }
        });
    }

    async getDataParent() {
        const dataSubscriptionPlan = await (this.$parent as Layout).getSubscriptionPlan();
        if (dataSubscriptionPlan) {
            this.paymentPlanClient = dataSubscriptionPlan[0];
            this.isPlanExceed = dataSubscriptionPlan[1];
            this.isPlanWarning = dataSubscriptionPlan[2];
            this.paymentSelected = dataSubscriptionPlan[3];
            this.loading = false;
        }
    }

    onMouseEnterCardChart(refs: { trigger: HTMLElement, wrapper: HTMLElement}) {
        const wrapper = refs.wrapper;
        const trigger = refs.trigger;
        const rectWrapper = trigger.getBoundingClientRect();
        const tooltip = wrapper.querySelector('.tooltip') as HTMLElement;
        const customTopOffset = 120;
        const customLeftOffset = 10;
        tooltip.style.position = 'fixed';
        tooltip.style.top = `${rectWrapper.top - customTopOffset}px`;
        tooltip.style.left = `${rectWrapper.left - customLeftOffset}px`;
        tooltip.style.display = 'block';
    }

    onMouseLeaveCardChart(refs: { wrapper: HTMLElement}) {
        const wrapper = refs.wrapper;
        const tooltip = wrapper.querySelector('.tooltip') as HTMLElement;
        tooltip.style.display = 'none';
    }
    
    onCardChartClick(process) {
        if (this.adminBlockedProcessIds.includes(process.id) || process.dataIsEmpty) {
            return;
        }

        if (process.phase === ProcessPhases.enum.INSCRIPTIONS) {
            this.goToListManagement(process);
        }

        this.goToEvaluation(process);
    }

    mounted() {
        this.loading = true;
        this.getCandidatesYear(this.yearActual);
        this.getProcessesReplacementListsYear(this.yearActual);
        this.getReplacementListsActive();
        this.getProcessesByYear();
        this.getDataParent();
    }
}
